

// export const paymentDetails = {
//     gpay:"Mswipe.1400062924023818@kotak",
//     paytm:"Mswipe.1400062924023818@kotak",
//     phonepe:"Mswipe.1400062924023818@kotak",
//     upi:"Mswipe.1400062924023818@kotak"
// }

export const paymentDetails = {
    gpay:"Mswipe.1400062924023818@kotak",
    paytm:"Mswipe.1400062924023818@kotak",
    phonepe:"Mswipe.1400062924023818@kotak",
    upi:"Mswipe.1400062924023818@kotak"
}

// export const paymentDetails = {
//     gpay:"74ix6m8my3xv@idbi",
//     paytm:"74ix6m8my3xv@idbi",
//     phonepe:"74ix6m8my3xv@idbi",
//     upi:"74ix6m8my3xv@idbi"
// }


// export const paymentDetails = {
//     gpay:"Mswipe.1400062924023818@kotak",
//     paytm:"Mswipe.1400062924023818@kotak",
//     phonepe:"Mswipe.1400062924023818@kotak",
//     upi:"Mswipe.1400062924023818@kotak"
// }